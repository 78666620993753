import React,{useContext} from 'react';
import { GlobalDataContext } from '../context/context';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import Gallery from './section-components/gallery';
import Footer from './global-components/footer';
// import Data from '../api/data.json'

const GalleryPage = () => {
    const { rpdata } = useContext(GlobalDataContext);
    return <div>
        <Navbar />
        <PageHeader headertitle="Gallery" Subheader="Gallery" backgroundImage={rpdata?.gallery?.[1]} />
        <Gallery />
        <Footer />
    </div>
}

export default GalleryPage

